import { graphql } from 'gatsby';
import App from 'routes/appDownload/AppDownload';

export default App;

export const query = graphql`
  query App {
    page: contentfulPageAppDownload {
      metaTitle
      metaDescription
      metaImage {
        ...image
      }
      heading
      detailsList {
        details
      }
      pageImage {
        file {
          url
          details {
            size
            image {
              width
              height
            }
          }
        }
      }
      appleImage {
        file {
          url
          details {
            size
            image {
              width
              height
            }
          }
        }
      }
      appleUrl
      googleImage {
        file {
          url
          details {
            size
            image {
              width
              height
            }
          }
        }
      }
      googleUrl
    }
  }
`;
