import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import LazyLoad from 'react-lazyload';

import extractMeta from 'utils/extract-meta';
import { withUIContext } from 'context/ui';
import GreyArea from 'components/grey-area';
import { Container, Row, Segment } from 'components/layout';
import IntegrationsHero from 'components/hero';
import HeroIntro from 'components/hero-intro';
import Image from 'components/image';
import ImageColumnVariant from 'components/image-column-variant';
import s from './AppDownload.scss';

import Check from 'assets/svg/icons/check.svg';
import SEO from 'components/seo';

import ProIDIcon from './ProIDIcon.png';
import PDMIcon from './PDMIcon.png';
import ProID from './app-store-proID.png';
import PDM from './app-store-pdm.png';

class Integrations extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    ui: PropTypes.object,
  }

  componentDidMount() {
    this.props.ui.setNavTheme('dark');
    const { page } = this.props.data;

    // Send visitor to app-store
    // const iOS = /ipad|iphone|ipod/i.test(navigator.userAgent) && !window.MSStream;
    // const android = /android/i.test(navigator.userAgent) && !window.MSStream;
//
    // if (window) {
      // if (iOS) {
        // window.location.href = page.appleUrl;
      // } else if (android) {
        // window.location.href = page.googleUrl;
      // }
    // }
  }

  componentWillUnmount() {
    this.props.ui.setNavTheme('dark');
  }

  render() {
    const { page } = this.props.data;
    const { pathname } = this.props.location;

    return (
      <Fragment>
        <SEO {...extractMeta(page)} description={page.heading} pathname={pathname} article />
        <div className={s.appDownload__title_container}>
          <Container>
            <Row>
              <div className={s.appDownload}>
                <div className={s.appDownload__column}>
                  <h1 className={s.appDownload__title}>即刻体验 Proxy 应用</h1>
                  <p className={s.appDownload__title_desc}>针对不同角色的用户，提供下载和体验不同版本的 Proxy 应用</p>
                </div>
                {/* <div className={s.appDownload__circle}></div> */}
                <div className={s.appDownload__column}>
                  <LazyLoad once>
                    <img
                      className={s.appDownload__image}
                      src={`${page.pageImage.file.url}?w=1020`}
                    />
                  </LazyLoad>
                </div>
              </div>
            </Row>
          </Container>   
        </div>
        <Container className={s.appDownload__subTitle}>
          <h1 className={s.appDownload__heading}>使用 下载应用打开个人信号</h1>
        </Container>
        <Row>
            <div className={s.appDownload__column}>
              <div className={s.appDownload__image_container_left}>
                <LazyLoad once>
                  <img
                    className={s.appDownload__app_image}
                    src={`${ProID}?w=1020`}
                  />
                </LazyLoad>
              </div>
            </div>
            <div className={s.appDownload__column}>
                <div className={s.appDownload__content_right}>
                  <div className={s.appDownload__icon_container}>
                    <LazyLoad once>
                      <img
                        className={s.appDownload__app_icon}
                        src={ProIDIcon}
                      />
                    </LazyLoad>

                    {page?.detailsList?.details?.length && (
                      <ul className={s.appDownload__details}>
                        {/* {page.detailsList.details?.map((detail, index) => (
                          <li key={index} className={s.appDownload__detail_container}>
                            <Check  className={s.appDownload__check}/>
                            <span className={s.appDownload__detail}>{ detail }</span>
                          </li>
                        ))
                        } */}
                        <li key={1} className={s.appDownload__detail_container}>
                          <Check  className={s.appDownload__check}/>
                          <span className={s.appDownload__detail}>使用手机开门</span>
                        </li>
                        <li key={2} className={s.appDownload__detail_container}>
                          <Check  className={s.appDownload__check}/>
                          <span className={s.appDownload__detail}>代替物理卡片</span>
                        </li>
                        <li key={3} className={s.appDownload__detail_container}>
                          <Check  className={s.appDownload__check}/>
                          <span className={s.appDownload__detail}>可灵活添加多个访问权限</span>
                        </li>
                      </ul>
                    )}
                  </div>
                  

                  <p className={s.appDownload__subTitle_desc}>使用你最熟悉的 下载应用，让您轻松出入工作场所或大楼。</p>
                  
                  <div className={s.appDownload__download_icon_container}>
                    <a href={page.appleUrl} className={s.appDownload__cta_link}>
                      <LazyLoad once>
                        <img
                          className={s.appDownload__cta_image}
                          src={page.appleImage.file.url}
                        />
                      </LazyLoad>
                    </a>
                    <a href={page.googleUrl} className={s.appDownload__cta_link}>
                      <LazyLoad once>
                        <img
                          className={s.appDownload__cta_image}
                          src={page.googleImage.file.url}
                        />
                      </LazyLoad>
                    </a>
                  </div>
                </div>
              </div>
          </Row>
          
          <Container className={s.appDownload__subTitle}>
            <h1 className={s.appDownload__heading}>使用 Proxy Device Manager 应用</h1>
            <h1 className={s.appDownload__heading}>管理和升级您的门禁</h1>
          </Container>
          <Row>
              <div className={`${s.appDownload__column} ${s.appDownload__pdm_bg}`}>
                <div className={s.appDownload__content_left}>
                  <div className={s.appDownload__icon_container}>
                    <LazyLoad once>
                      <img
                        className={s.appDownload__app_icon}
                        src={PDMIcon}
                      />
                    </LazyLoad>

                    {page?.detailsList?.details?.length && (
                      <ul className={s.appDownload__details}>
                        <li key={1} className={s.appDownload__detail_container}>
                          <Check  className={s.appDownload__check}/>
                          <span className={s.appDownload__detail}>设置和管理门禁</span>
                        </li>
                        <li key={2} className={s.appDownload__detail_container}>
                          <Check  className={s.appDownload__check}/>
                          <span className={s.appDownload__detail}>控制门禁信号感应距离</span>
                        </li>
                        <li key={3} className={s.appDownload__detail_container}>
                          <Check  className={s.appDownload__check}/>
                          <span className={s.appDownload__detail}>使用手机 OTA 升级门禁</span>
                        </li>
                      </ul>
                    )}
                  </div>
                  

                  <p className={s.appDownload__subTitle_desc}>管理员可以用 Proxy 设备管理器来快速设置和管理 Proxy 设备</p>
                  
                  <div className={s.appDownload__download_icon_container}>
                    <a href={`https://apps.apple.com/cn/app/proxy-device-manager/id1535132472`} className={s.appDownload__cta_link}>
                      <LazyLoad once>
                        <img
                          className={s.appDownload__cta_image}
                          src={page.appleImage.file.url}
                        />
                      </LazyLoad>
                    </a>
                    <a href={`https://proxy.cn/pdm-latest.apk`} className={s.appDownload__cta_link}>
                      <LazyLoad once>
                        <img
                          className={s.appDownload__cta_image}
                          src={page.googleImage.file.url}
                        />
                      </LazyLoad>
                    </a>
                  </div>
                </div>
              </div>

              <div className={s.appDownload__column}>
                <div className={s.appDownload__image_container_right}>
                  <LazyLoad once>
                    <img
                      className={s.appDownload__app_image_with_shadow}
                      src={`${PDM}?w=1020`}
                    />
                  </LazyLoad>
                </div>
              </div>
          </Row>
      </Fragment>
    );
  }
}

export default withUIContext(Integrations);
